import { useStore } from "@/lib/useStore";
import { Modules } from "@/store/types";
import {
  Actions,
  Getters,
  States
} from "@/store/modules/social/recommendation/types";

const { useActions, useGetters, useState } = useStore(Modules.RECOMMENDATION);

const useRecommendationActions = () => {
  const {
    [Actions.GET]: get,
    [Actions.GET_DEFAULT]: getDefault,
    [Actions.SAVE]: save,
    [Actions.REMOVE]: remove
  } = useActions([
    Actions.GET,
    Actions.SAVE,
    Actions.REMOVE,
    Actions.GET_DEFAULT
  ]);
  return { get, save, remove, getDefault };
};

const userRecommendationStates = () => {
  const {
    [States.RECOMMENDATION]: recommendation,
    [States.DEFAULT_RECOMMENDATION]: defaultRecommendation,
    [States.ERROR]: error,
    [States.LOADING]: loading
  } = useState([
    States.RECOMMENDATION,
    States.ERROR,
    States.LOADING,
    States.DEFAULT_RECOMMENDATION
  ]);
  return { recommendation, error, loading, defaultRecommendation };
};

const useRecommendationGetters = () => {
  const {
    [Getters.HAS_CHANGED]: hasChanged,
    [Getters.IS_EMPTY]: isEmpty
  } = useGetters([Getters.HAS_CHANGED, Getters.IS_EMPTY]);
  return { hasChanged, isEmpty };
};

export const useRecommendation = () => ({
  ...useRecommendationActions(),
  ...userRecommendationStates(),
  ...useRecommendationGetters()
});
