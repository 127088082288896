
































































































































































import { useRecommendation } from "@/lib/social/recommendation";
import { defineComponent, ref } from "@vue/composition-api";
import { useValidation } from "@/lib/validate";
import { VForm } from "@/types/vimbApi/vuetify";

export default defineComponent({
  setup() {
    const {
      recommendation,
      defaultRecommendation,
      error,
      save,
      remove,
      isEmpty,
      hasChanged
    } = useRecommendation();
    const form = ref<VForm>();
    const confirmDefaultIsOpened = ref<boolean>(false);
    const confirmDefaultField = ref<string>("");

    const focusError = () => {
      const selector = ".error--text:first-of-type + *";
      const error = form.value?.$el.querySelector(selector) as HTMLElement;
      if (!error) return;
      error.scrollIntoView();
      error.focus();
    };

    const submit = () => {
      if (!form.value?.validate()) focusError();
      else if (isEmpty()) remove(recommendation.value);
      else save(recommendation.value);
    };

    const placeholderFor = (field: string) => {
      return `{{${field}}}`;
    };

    return {
      recommendation,
      defaultRecommendation,
      save,
      remove,
      error,
      hasChanged,
      isEmpty,
      submit,
      form,
      placeholderFor,
      requestSetDefault: (field: string) => {
        confirmDefaultField.value = field;
        confirmDefaultIsOpened.value = true;
      },
      confirmDefault: () => {
        const field = confirmDefaultField.value;
        const value = defaultRecommendation.value[field];
        recommendation.value[field] = value
          ?.replace?.(/[ \t]*\n[ \t]*/g, "\n")
          ?.replace?.(/(^\s*|\s*$)/g, "");
        confirmDefaultIsOpened.value = false;
      },
      confirmDefaultField,
      confirmDefaultIsOpened,
      ...useValidation()
    };
  }
});
