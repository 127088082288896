















import { useRecommendation } from "@/lib/social/recommendation";
import { defineComponent } from "@vue/composition-api";

import RecommendationForm from "./recommendation/Form.vue";

export default defineComponent({
  components: {
    RecommendationForm
  },
  setup() {
    const { loading, get, getDefault } = useRecommendation();

    get();
    getDefault();

    return { loading };
  }
});
